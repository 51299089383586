<template>
  <div id="page-user-list">

    <div class="vx-card p-4 mb-4">
      <div class="mb-4">
          <div class="flex flex-wrap items-center justify-between">
            <div>
            <feather-icon icon="UserIcon" class="mr-2" svgClasses="w-5 h-5" />
            <span class="leading-none font-medium">Package Images</span>
            </div>
            <vs-button @click="openModal" color="primary" type="filled">Add New Image</vs-button>
          </div>
      </div>
      <div class="vx-row mb-6">
        <template v-if="images.length > 0">
          <div  v-for="image in images" :key="image.id" class="vx-col w-full md:w-1/2">
            <div class="card-block">
              <file-pond
                name="test"
                ref="pond"
                label-idle="Drop files here..."
                :allow-multiple="false"
                accepted-file-types="image/jpeg, image/png"
                instant-upload="false"
                :disabled="editImageInput.id != image.id"
                :files="image.url"
                @addfile="handleFileUpdate"
              />
              <vs-input
                  class="w-full mb-4"
                  label="Alt image"
                  :value="(editImageInput.id == image.id && editImageInput.alt_text != '')?editImageInput.alt_text:image.alt"
                  :disabled="editImageInput.id != image.id"
                  @change="updateAltText"
                />

               <div class="flex flex-wrap items-center justify-between mb-2">

                <vs-button
                  icon="save"
                  v-if="editImageInput.id == image.id"
                  type="border"
                  size="small"
                  @click="updatePackageImage"
                  color="primary">
                Update
                </vs-button>
                <vs-button
                  v-else
                  icon="create"
                  type="border"
                  @click="editImage(image.id)"
                  size="small"
                  color="primary">
                Edit
                </vs-button>


                <vs-button
                  icon="delete"
                  type="border"
                  size="small"
                  @click="deleteImage(image.id)"
                  color="danger">
                  Delete
                </vs-button>
              </div>
            </div>
          </div>

        </template>
      </div>
    </div>

        <!-- Save & Reset Button -->
    <div class="vx-card p-4 mb-4">
        <SettingInfo></SettingInfo>
        <div class="flex flex-wrap items-center justify-between">
          <vs-button @click="saveSettingInfo" class="ml-auto" >Save Changes</vs-button>
        </div>
    </div>


    <vs-popup
      classContent="popup-example"
      title="Add New Image"
      :active.sync="addImageModalActive">
      <file-pond
        name="test"
        ref="pond"
        label-idle="Drop files here..."
        :allow-multiple="false"
        accepted-file-types="image/jpeg, image/png"
        instant-upload="false"
        @addfile="handleFileAdd"

      />
      <vs-input
        class="w-full mb-4"
        label="Alt image"
        v-model="saveImage.alt_text"
      />

      <vs-button
        icon="add"
        type="border"
        size="small"
        @click="addImage"
        color="primary">
      Add
      </vs-button>
    </vs-popup>
	</div>
</template>

<script>
import vSelect from 'vue-select'
import {PACKAGE_REGION} from '../../../constant/entity-identifier'
import { mapGetters, mapActions } from 'vuex'
import loaderMixin from '../../../mixins/loaderMixin';

// Import FilePond
import vueFilePond from 'vue-filepond';

// Import plugins
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js';

// Import styles
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
const FilePond = vueFilePond( FilePondPluginFileValidateType, FilePondPluginImagePreview );

import SettingInfo from './common/SettingInfo';
import {storeImage, updateImage, destroyImage} from '../../../services/image';

export default {
  data() {
    return {
      addImageModalActive: false,
      saveImage: {
        image: '',
        alt_text: ''
      },
      editImageInput: {
        id:'',
        image: '',
        alt_text: ''
      },
    }
  },
  mixins: [
    loaderMixin
  ],
  components: {
    FilePond,
    vSelect,
    SettingInfo
  },
  computed: {
    ...mapGetters({
        form: 'main_package/getFormObj',
        images: 'main_package/getImages'
    }),
    packageId() {
        return this.$route.params.packageId;
    },
  },
  created() {
    this.clearForm();
    this.openLoading();
     this.fetchAndSetPackageById(this.packageId).then(() => {
       this.closeLoading();
     }).catch((r) => this.$router.push('/'));
  },
  methods: {
    ...mapActions('main_package', [
        'updatePackageAction',
        'fetchAndSetPackageById',
         'clearForm'
    ]),

    openModal() {
      this.addImageModalActive=true;
      this.saveImage.image = '';
      this.saveImage.alt_text = '';
    },

    prepareForm() {
      let formData = new FormData();
      formData.append("entity_id", this.packageId);
      formData.append("entity", 'package');
      formData.append("images[]", this.saveImage.image);
      formData.append("alt[]", this.saveImage.alt_text);

      return formData;
    },

    handleFileAdd(image_name, files) {
      this.saveImage.image = files.file;
    },
    addImage() {
      this.openLoading();
      storeImage(this.prepareForm(PACKAGE_REGION)).then((response) => {
        this.fetchAndSetPackageById(this.packageId).then(() => {
          this.addImageModalActive = false;
          this.saveImage.image = '';
          this.saveImage.alt_text = '';
          this.closeLoading();
        });
      });
    },

    /**
     * Edit Image methods
     */
    editImage(id) {
      this.editImageInput.id = id;
      this.editImageInput.alt_text = '';
      this.editImageInput.image = '';
    },
    updateAltText(event) {
      this.editImageInput.alt_text = event.target.value;
    },
    handleFileUpdate(image_name, files) {
        this.editImageInput.image = files.file;
    },
    updatePrepareForm() {
      let formData = new FormData();
      formData.append("_method", 'POST');
      formData.append("entity_id", this.packageId);
      formData.append("entity", 'package');
      formData.append("images[]", this.editImageInput.image);
      formData.append("alt[]", this.editImageInput.alt_text);

      return formData;
    },
    updatePackageImage() {
      this.openLoading();
      updateImage(
        this.editImageInput.id,
        this.updatePrepareForm()
      ).then(() => {
          this.fetchAndSetPackageById(this.packageId).then(() => {
            this.editImageInput.id = '';
            this.editImageInput.alt_text = '';
            this.editImageInput.image = '';
            this.closeLoading();
          });
      });
    },

    /**
     * Update Package
     */
    saveSettingInfo() {
      this.openLoading();
      this.updatePackageAction({
        packageId: this.packageId,
        data: this.form
      }).then(() => {
        this.closeLoading();
        this.$vs.notify({
            text: 'Package saved successfully.',
            title: 'Package Saved',
            color: 'success',
            position: 'top-right'
        });
        this.$router.push('/packages');
      });
    },

  /**
   * Delete Image
   */
    deleteImage(id) {
      this.openLoading();
      destroyImage(id).then(() => {
        this.fetchAndSetPackageById(this.packageId).then(() => {
            this.closeLoading();
        });
      })
    },
  },
}
</script>


<style lang="scss">
#page-user-list {
  .card-block {
    border:1px solid #ccc!important;
    border-radius:16px;
    padding: 12px;
    margin-bottom: 8px;
  }
}
</style>
