<template>
  <div class="mb-6"> 
       <vs-checkbox 
          class="w-full mt-4" 
          :checked="form.is_sponsored"
          :value="form.is_sponsored"
          @change="updateForm({key: 'is_sponsored', value: $event.target.checked})"> 
          Sponsored 
        </vs-checkbox>    
       <vs-checkbox 
          class="w-full mt-4" 
          :checked="form.status"
          @change="updateForm({key: 'status', value: $event.target.checked})"> 
          Status 
        </vs-checkbox>

           <vs-checkbox 
          class="w-full mt-4" 
             :checked="form.full_payment"
          @change="updateForm({key: 'full_payment', value: $event.target.checked})"> 
          Full Payment (At the time of Booking)? 
        </vs-checkbox>
  </div>
</template>


<script>
import vSelect from 'vue-select'
import { mapGetters, mapActions } from 'vuex'
export default {
  components: {
    vSelect
  },
  computed: {
    ...mapGetters({
        form: 'main_package/getFormObj',
        validation: 'validation/getValidationErrors'
    })
  },
  methods: {
    ...mapActions({
      updateForm: 'main_package/updateForm'
    })
  }
}
</script>


<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
